<template>
    <div>
        <div class="filter-panel">
            <input type="text" style="margin-right: 30px; width: 242px;" class="cs-input"
                   placeholder="搜索姓名全称/手机号/所属公司全称" v-model="keyword">
            <button class="btn btn-primary" @click="queryAppleLog()">查询</button>
        </div>
        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:thead>
                    <tr>
                        <th>姓名</th>
                        <th>手机号</th>
                        <th>所属公司</th>
                        <th>公司人员审核人</th>
                        <th>当前状态</th>
                        <th>操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="applyLogs.length > 0">
                        <tr v-for="log in applyLogs" :key="log.id">
                            <td>{{log.userName || '-'}}</td>
                            <td>{{log.phone || '-'}}</td>
                            <td>{{log.companyName || '-'}}</td>
                            <td>{{log.companyAudit || '-'}}</td>
                            <td>
                <span v-if="log.state != undefined" :class="{'allow-click': log.state && log.state !== 0}"
                      @click="lookApplyCardState(log)"
                >
                  {{cardApplyState[log.state]}}
                </span>
                                <tempalte v-else>
                                    -
                                </tempalte>
                            </td>
                            <td>
                                <div class="btn-group">
                                    <button
                                            type="button"
                                            class="btn btn-primary dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                    >
                                        操作
                                    </button>
                                    <ul class="dropdown-menu" style="min-width: 118px; width: 118px;">
                                        <li @click="issueCard(log)" v-if="log.state !== 1">
                                            <a style="width: 100%;">发卡</a>
                                        </li>
                                        <li @click="rejectApply(log.id)" v-if="log.state === 0">
                                            <a style="width: 100%;">拒发卡</a>
                                        </li>
                                        <li @click="issueCard(log)" v-if="log.state === 1">
                                            <a style="width: 100%;">补卡</a>
                                        </li>
                                        <li @click="recycleCard(log.id, log.deposit)" v-if="log.state === 1">
                                            <a style="width: 100%;">回收门卡</a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>
        </div>

        <CSDialog
                :dialogShowConfirmBtn="false"
                :dialogVisible="panelGroupDialogVisible"
                dialog-header-class="alert-bg"
                dialogCancelBtnText="关闭"
                dialogTitle="已发卡"
                dialogWidth="730px"
                @onClose="panelGroupDialogVisible = false"
        >
            <div
                    slot="dialog-content"
                    style="padding: 30px; font-size: 24px; text-align: center"
            >
                <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
                    <div style="margin-bottom: 23px;">
                        <label style="width: 100px; text-align: right; margin-right: 40px;vertical-align: top"
                        >门卡编号</label
                        >
                        <span style="width: 380px; display: inline-block;text-align: left;">
                            {{`${applyLog.cartNo}${applyLog.copy == 1?'此卡允许被复制':'此卡不允许被复制'}`}}
                        </span>
                    </div>
                    <div style="margin-bottom: 23px">
                        <label style="width: 100px; text-align: right; margin-right: 40px"
                        >门卡押金</label
                        >
                        <span style="width: 380px; display: inline-block;text-align: left;">
                            {{applyLog.deposit}}元
                        </span>
                    </div>
                    <div style="margin-bottom: 23px">
                        <label style="width: 100px; text-align: right; margin-right: 40px;"
                        >授权面板</label>
                        <div style="display: inline-block">
                            <div style="width: 380px;text-align: left;" v-for="item in applyLog.datas" :key="item.id">
                                {{item.deviceNo}}/{{item.id}}/{{item.buildingName}}{{item.floor}}层
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </CSDialog>

        <AccessCardWrite :visible="accessCardDialog.visible" @onClose="accessCardDialog.visible = false"
                         @refresh="queryAppleLog" :id="accessCardDialog.id" :userId="accessCardDialog.userId"
        />
        <CSDialog dialogTitle="拒发卡" dialogWidth="596px" dialog-confirm-btn-text="确定"
                  :dialogVisible="refuseCardDialog.visible" @onConfirm="refuseCardDialog.onConfirm"
        >
            <template v-slot:dialog-content>
                <div class="refuse-dialog-content">
                    <span class="refuse-dialog-content-label">拒发原因</span>
                    <div class="refuse-dialog-content-content">
                        <textarea placeholder="限50个字,必填" maxlength="50" v-model="refuseCardDialog.reason"></textarea>
                    </div>

                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
    import CSDialog from "@/components/common/CSDialog";
    import AccessCardWrite from "@/components/AccessCardWrite/AccessCardWrite";
    import {queryApplyAccessCardUrl, recycleCardUrl, rejectApplyCardUrl} from "@/requestUrl";
    import RightWidgetMenu from "@/components/common/RightWidgetMenu";
    import CSTable from "@/components/common/CSTable";

    export default {
        name: "AccessCardManage",
        components: {
            CSTable,
            RightWidgetMenu,
            CSDialog,
            AccessCardWrite,
        },
        created() {
            window.addEventListener("keydown", this.queryAppleLogDown);
        },
        beforeDestroy() {
            window.removeEventListener("keydown", this.queryAppleLogDown);
        },
        deactivated() {
            window.removeEventListener("keydown", this.queryAppleLogDown);
        },
        data() {
            return {
                applyLog: '',
                panelGroupDialogVisible: false,
                accessCardDialog: {
                    visible: false,
                    id: 0,
                    userId: '',
                },
                filterHeight: 0,
                cardApplyState: {
                    0: '未发卡',
                    1: '已发卡',
                    2: '拒发卡',
                    3: '已回收门卡',
                },
                keyword: '',
                applyLogs: [],
                // 拒绝发卡
                refuseCardDialog: {
                    visible: false,
                    id: '',
                    reason: '',
                    onConfirm: () => {
                    }
                },
            }
        },
        mounted() {
            this.queryAppleLog();
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
            window.addEventListener('resize', () => {
                this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
            })
        },
        updated() {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        },
        methods: {
            queryAppleLogDown(e) {
                if (e.keyCode == 13) {
                    this.queryAppleLog();
                }
            },
            menuList(arrs = ['返回', '前进', '刷新', '复制']) {
                return arrs.map(i => ({name: i, handler: (e = '') => console.log(e, i)}))
            },
            parseStyle(styObj) {
                let styStr = '';
                Object.entries(styObj).forEach(([key, val]) => {
                    styStr += `${key}:${val};`
                })
                return styStr;
            },
            /**
             * 查看门卡状态
             * @param {Object} applyLog 申请记录
             * */
            lookApplyCardState(applyLog) {


                if (applyLog.state == 1) {
                    this.applyLog = applyLog;
                    this.panelGroupDialogVisible = true;
                    return
                }

                const wrapDivStyle = {
                    'text-align': 'left',
                    'font-size': '20px',
                    'vertical-align': 'middle',
                };
                const itemLabelStyle = {
                    'margin-right': '40px'
                };
                const applyState = {
                    1: {
                        title: '已发卡',
                        messageHtml: `
            <div style="${this.parseStyle(wrapDivStyle)}margin-bottom: 20px;">
                <span style="${this.parseStyle(itemLabelStyle)}">门卡编号</span>
                <span>${applyLog.cartNo}</span>
            </div>
            <div style="${this.parseStyle(wrapDivStyle)}">
                <span style="${this.parseStyle(itemLabelStyle)}">门卡押金</span>
                <span>${applyLog.deposit}元</span>
            </div>
          `
                    },
                    2: {
                        title: '拒发卡',
                        messageHtml: `<p>${applyLog.refuseReason}</p>`
                    },
                    3: {
                        title: '查看已回收信息',
                        messageHtml: `
          <div style="${this.parseStyle(wrapDivStyle)}margin-bottom: 20px;">
              <span style="${this.parseStyle(itemLabelStyle)}">曾用门卡编号</span>
              <span>${applyLog.lastCardNo}</span>
          </div>
          <div style="${this.parseStyle(wrapDivStyle)}">
              <span style="${this.parseStyle(itemLabelStyle)}">已退门卡押金</span>
              <span>${applyLog.depositRefund}元</span>
          </div>
        `
                    }
                }
                const {title, messageHtml} = applyState[applyLog.state];
                this.$CSDialog.alert({
                    title,
                    messageHtml,
                })
            },
            /**
             * 发卡
             * @param {Object} log 申请记录
             * */
            issueCard(log) {
                this.accessCardDialog = {
                    visible: true,
                    id: log.id,
                    userId: log.userId,
                }
            },
            /**
             * 拒绝发卡
             * @param {Number} id 申请记录id
             * */
            rejectApply(id) {
                this.refuseCardDialog.id = id;
                this.refuseCardDialog.visible = true;
                this.refuseCardDialog.reason = '';
                this.refuseCardDialog.onConfirm = () => {
                    if (this.refuseCardDialog.reason === '') {
                        this.$vc.toast('拒发原因必填');
                        return;
                    }
                    this.$fly.post(rejectApplyCardUrl, {
                        id,
                        reason: this.refuseCardDialog.reason
                    })
                        .then(res => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.refuseCardDialog.visible = false;
                            this.queryAppleLog();
                        })
                }
            },
            /**
             * 回收门卡
             * @param {Number} id 门卡id
             * @param {NUmber} deposit 门卡押金
             * */
            recycleCard(id, deposit) {
                this.$CSDialog.confirm({
                    message: `确定回收门卡吗？确定后，请将押金（${deposit}元）退还给用户，并且此卡将无法使用，需重新写卡才可继续使用。`,
                    onConfirm: () => {
                        this.$fly.get(recycleCardUrl, {id})
                            .then(res => {
                                if (res.code !== 0) {
                                    return;
                                }
                                this.$CSDialog.instance.closeDialog();
                                this.queryAppleLog();
                            })
                    }
                })
            },
            // 获取申请记录
            queryAppleLog() {
                const {keyword: search} = this;
                this.$fly.post(queryApplyAccessCardUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    search
                })
                    .then(res => {
                        this.applyLogs = res?.data || [];
                    })
            }
        }
    }
</script>

<style scoped lang="stylus">
    .refuse-dialog-content
        padding 30px
        font-size 24px

        &-label
            width 96px
            display inline-block
            vertical-align top
            margin-right 40px

        &-content
            display inline-block
            vertical-align top

            textarea
                width 400px
                height 180px
                padding 5px 10px
                background #F0F0F0
                border-radius 10px
                resize none
                border none


</style>
